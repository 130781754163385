@include mobile{
	.logo{
		width: 100%;
		max-width: 80%
	}
	.mainBannerSlider{
		height: 500px; 
	}
	.mainBannerSlider .item .bannerDsc .bannerDscInner{
		width: 100%;
		h1{
			font-size: 20px;
		}
		p{
			font-size: 14px;
		}
		.banner-buttons a{
			width: auto;
			padding: 10px; 
			margin-right: 14px;
		}
	}
	.bottomSlidersWrap{
		margin-top: 40px;
	}
	.newsSlider{
		width: 100%;
		padding-right: 0; 
	}
	.newsSlider .center_dsc h1{
		font-size: 20px;
	}
	.listedItem{ 
		padding-left: 0;
		margin-top: 20px;
	}
	.my_row{
		margin-left: 0;
		margin-right: 0;
	}
	.services ul li{
		width: 100%;
		padding: 0; 
		margin-top: 30px;
	}
	.rek_banner{
		margin: 30px 0 30px;
	}
	.partners-value-wrap .tweetsArea{
		margin-top: 30px;
		iframe{
			transform:scale(1.0);
		}
	}
	.partners-value-wrap .valuesArea{
		width: 100%;
		margin-top: 0px;
	}
	.services ul li .overlay h2{
		font-size: 18px;
	}
	.footerWrap {
		padding: 20px 0;
		.leftfooterside{
			max-width: 100%;
		}
		.footer_logo{
			width: 100%;
		}
		.footerSocialMedia{
			max-width: 100%;
		}
		.newsletter{
			float: left;
			max-width: 100%;
			margin-top: 30px;
			width: 100%;
		}
		.footerSocialMedia{
			margin-top: 30px;
		}
	}

	.side_bar{
		padding-left: 0;
		margin-top: 20px;
	}
	.mainThumb{
		height: 320px;
	}
	.raports_wrap li p{
		font-size: 14px;
	}
	.raports_wrap{
		margin-top: 0;
	}
	.kontakt_wrap {
		margin-top: 0;
		.input_wrap{
			width: 100%;
			padding-left: 0;
			padding-right: 0;
			&:nth-child(2n){
				padding-left: 0;
			}
		}
	}
	.input-group h1{
		font-size: 16px;
	}
	.tabs-menu li{
		width: 50%;
		a{
			font-size: 14px;
		}
	}
	.top-wrap-filters .input-group{
		min-height: auto;
		border-right: 0;
		margin-bottom: 20px;
	}
	.bottom-wrap-filters .input-group{
		min-height: auto;
		border-right: 0;
		margin-bottom: 20px; 
	}
	.table_details table tbody tr td.grey-bg span.prod-img{
		width: 100px;
	}
	.table_details table tbody tr td.grey-bg span.prod-name{
		width: 100px;
	}
	.table-responsive{
		width: 100%;
		overflow-y:scroll;
	}
	.main_nav{
		float: left;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		-webkit-overflow-scrolling: touch; 
		white-space: nowrap;
		padding: 20px 0;
		ul{
			width: 100%;
			display: -webkit-box; 
		    display: -ms-flexbox; 
		    display: flex;
		    -webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		    justify-content: space-between;
		}

	}
	.header_top_wrap .language_nav{
		border-right: 0;
	}
	.header_top_wrap{
		display: none;
	}
	.second_nav{
		margin-right: 0;
		width: 100%;
		margin-bottom: 20px;
		ul{
			li{
				width: 100%;
				text-align: center;
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
}


@include tablet{
	.mainBannerSlider .item .bannerDsc{
		h1{
			font-size: 40px;
		}
		p{

		}
	}
	.mainBannerSlider .item .bannerDsc .bannerDscInner{
		width: 100%;
	}
	.newsSlider .center_dsc h1{
		font-size: 24px;
	}
	.newsSlider{
		height: 315px;
	}
	.header_top_wrap{
		display: none;
		margin-top: 20px;
	}
	.hamburger{
		margin-top: 35px;
	}
	.my_row{
		margin-left: -15px;
		margin-right: -15px;
	}
	.services ul li{
		padding: 0 15px;
	}
	.services ul li .overlay h2{
		line-height: 1.3em;
		font-size: 20px;
	}
	.partners-value-wrap .valuesArea{
		width: 100%;
	}
	.footerWrap .footer_logo{
		width: 100%;
		height: auto;
		img{
			max-width: 85%;
		}
	}
	.footerWrap .footerSocialMedia ul li{
		margin-right: 25px;
	}
	.footerWrap .footerSocialMedia{
		padding-right: 10px;
	}
	.footerWrap .newsletter form input[type=text]{
		width: 200px;
	}
	.table-responsive{
		width: 100%;
		overflow-y: scroll;
	}
}
@include tablet-big{
	.partners-value-wrap .valuesArea{
		width: 100%;
	}
	.footerWrap .footer_logo{
		width: 100%;
		height: auto;
		img{
			max-width: 85%;
		}
	}
	.mainBannerSlider .item .bannerDsc .bannerDscInner{
		width: 60%;
	}
	.hamburger{
		display: none;
	}
	.second_nav{
		margin-right: 40px;
	}
	.logo{
		width: 270px;
	}
	.header_top_wrap{
		margin-top: 20px;
	}
}





.hamburger {
	    width: 30px;  
	    float: right;
	    height: 14px;
	    margin-top: 25px;
		span {
			width: 100%;
			float: left;
			height: 2px;
		    background: #8dc63f;
			margin-bottom: 4px;
	        transition: $transition1;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.toggled {
	        span {
	            &:first-child {
	                margin-top: 7px;
	                margin-bottom: 2px;
	                transform: rotate(45deg);
	                transition: $transition1;
	            }
	            &:nth-child(2) {
	                opacity: 0;
	                transition: $transition1;
	            }
	            &:nth-child(3) {
	                margin-top: -10px;
	                transform: rotate(-45deg);
	                transition: $transition1;
	            }
	        }
		}
	}
