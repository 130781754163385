.header_top_wrap{
	float: right;
	.language_nav{
		float: left;
	    margin-right: 7px;
	    border-right: 1px solid #918f90;
	    padding-right: 7px;
		li{
			float: left;
			&:last-child a{
				&:before{
					display: none;
				}
			}
		}
		a{
			font-size: 14px;
			position: relative;
			text-decoration: none;
			display: block;
			color: #000; 
			&:before{
				content: "/";
				color: #000;
				float: right;
				margin-left: 4px;
				margin-right: 4px;
			} 
		}
	}
	.login_area{
		float: right;
		a{
			text-decoration: none;
			color: #000;
			text-transform: uppercase;
			background: url('../images/login_icon.png') no-repeat right;
			padding-right: 28px;
			padding-top: 1px;
		}
	}
}
.header_inner{
	float: left;
	width: 100%;
	padding: 30px 0 ;
}
.not_home{
	.main_nav{
		border-top: 2px solid #c9c8c8;
		ul{
			float: none;
			display: block;
			text-align: center;
		}
		width: 100%;
		padding: 20px 0;
		ext-align: center;
		li{
			display: inline-block;
			float: none;
			text-align: center; 
		}
	}
}
.logo{
	float: left; 
	width: 338px;
	height: 96px;
}
.main_nav{
    float: right;
    padding: 40px 0px 20px;
    border-bottom: 2px solid #c9c8c8;
	ul{
		float: left;
		width: 100%;
		li{
			float: left;
			margin-right: 30px;
			a{
				text-decoration: none;
				font-size: 14px;
				color: #000;
			}
			&:last-child{
				margin-right: 0;
			}
		}
	}
}
.second_nav{
    float: left;
    margin-right: 80px;
	ul{
		float: left;
		width: 100%;
		li{
			float: left;
			margin-right: 30px;
			a{
				text-decoration: none;
				font-size: 14px;
				color: #000;
				text-transform: uppercase;
			}
			&:last-child{
				margin-right: 0;
			}
		}
	}
}


