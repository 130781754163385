.footerWrap{
	float: left;
	width: 100%;
	background: #8DC63F;
	padding: 36px 26px 50px;
	margin-top: 56px;
	.footer_logo{
		float: left;
		width: 388px;
		height: 94px;
	}
	.leftFooterDsc{
		margin-top: 30px;
		p{
			color: #fff;
			font-size: 15px;
			line-height: 1.3em;
		}
	}
	.leftfooterside{
		max-width: 410px; 
	}
	.footerSocialMedia{
		display: block;
		margin: 0 auto;
		max-width: 248px;
		h1{
			font-size: 23px;
			color: #fff;
		}
		ul{
			float: left;
			width: 100%;
			border-top: 2px solid #fff;
			padding-top: 15px;
			margin-top: 15px;
			li{
				float: left;
				margin-right: 33px;
				&:last-child{
					margin-right: 0;
				}
				&.intagram{
					img{
						width: 45px;
					}
				}
				&.facebook{
					img{
						width: 30px;
					}
				}
				&.youtube{
					img{
						width: 42px;
					}
				}
				&.googleplus{
					img{
						width: 47px;
					}
				}
				img{
					height: 30px;
				}
			}
		}
	}
	.newsletter{ 
		float: right;
		max-width: 270px;
		h1{
			font-size: 25px;
			text-transform: uppercase;
			color: #fff;
		}
		.newsletterHolder{
			float: left;
			width: 100%;
			border-top: 2px solid #fff;
			padding-top: 15px;
			margin-top: 15px;
			p{
				color: #fff;
				font-size: 11px;
				font-weight: bold;
			}
		}
		form{
			margin-top: 23px;
			input{
				border: 0;
				-webkit-appearance: none;
				-moz-appearance: none;
				-o-appearance: none;
				outline: none;
			}
			input[type="text"]{
				width: 210px;
				opacity: 0.6;
				padding: 8px;
				float: left;
			}
			input[type="submit"]{
				background: url('../images/checked1.svg') no-repeat center #fff; 
				background-size: 20px;
				text-indent: -9999em;
				cursor: pointer;
				padding: 8px 17px;
			}
		}
	}
}