/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*====================================
=            GRID GLOBALS            =
====================================*/
/*=====  End of GRID GLOBALS  ======*/
/*===================================
=            TRANSITIONS            =
===================================*/
/*=====  End of TRANSITIONS  ======*/
/*=================================
=            STRUCTURE            =
=================================*/
/*=====  End of STRUCTURE  ======*/
/*==============================
=            COLORS            =
==============================*/
/*=====  End of COLORS  ======*/
/* This stylesheet generated by Transfonter (https://transfonter.org) on October 13, 2017 12:39 PM */
@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-BdCn.eot");
  src: url("../fonts/HelveticaNeueLTPro-BdCn.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-LtIt.eot");
  src: url("../fonts/HelveticaNeueLTPro-LtIt.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-LtIt.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-LtIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-CnO.eot");
  src: url("../fonts/HelveticaNeueLTPro-CnO.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-CnO.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-CnO.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.eot");
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-BlkExO.eot");
  src: url("../fonts/HelveticaNeueLTPro-BlkExO.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BlkExO.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkExO.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Cn.eot");
  src: url("../fonts/HelveticaNeueLTPro-Cn.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Cn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Cn.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Blk.eot");
  src: url("../fonts/HelveticaNeueLTPro-Blk.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Blk.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Blk.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-XBlkCn.eot");
  src: url("../fonts/HelveticaNeueLTPro-XBlkCn.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-XBlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-XBlkCn.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-BdEx.eot");
  src: url("../fonts/HelveticaNeueLTPro-BdEx.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BdEx.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdEx.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Hv.eot");
  src: url("../fonts/HelveticaNeueLTPro-Hv.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Hv.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Hv.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Bd.eot");
  src: url("../fonts/HelveticaNeueLTPro-Bd.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Bd.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Bd.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Lt.eot");
  src: url("../fonts/HelveticaNeueLTPro-Lt.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Lt.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Lt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-BlkEx.eot");
  src: url("../fonts/HelveticaNeueLTPro-BlkEx.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BlkEx.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkEx.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-MdEx.eot");
  src: url("../fonts/HelveticaNeueLTPro-MdEx.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-MdEx.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-MdEx.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-ThEx.eot");
  src: url("../fonts/HelveticaNeueLTPro-ThEx.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-ThEx.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-ThEx.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-LtCnO.eot");
  src: url("../fonts/HelveticaNeueLTPro-LtCnO.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-LtCnO.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-LtCnO.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-HvCn.eot");
  src: url("../fonts/HelveticaNeueLTPro-HvCn.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-HvCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-HvCn.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-MdCnO.eot");
  src: url("../fonts/HelveticaNeueLTPro-MdCnO.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-MdCnO.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-MdCnO.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-LtEx.eot");
  src: url("../fonts/HelveticaNeueLTPro-LtEx.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-LtEx.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-LtEx.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-HvEx.eot");
  src: url("../fonts/HelveticaNeueLTPro-HvEx.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-HvEx.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-HvEx.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-HvCnO.eot");
  src: url("../fonts/HelveticaNeueLTPro-HvCnO.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-HvCnO.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-HvCnO.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-BdExO.eot");
  src: url("../fonts/HelveticaNeueLTPro-BdExO.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BdExO.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdExO.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-UltLtCn.eot");
  src: url("../fonts/HelveticaNeueLTPro-UltLtCn.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-UltLtCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-UltLtCn.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-ThIt.eot");
  src: url("../fonts/HelveticaNeueLTPro-ThIt.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-ThIt.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-ThIt.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-XBlkCnO.eot");
  src: url("../fonts/HelveticaNeueLTPro-XBlkCnO.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-XBlkCnO.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-XBlkCnO.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-LtCn.eot");
  src: url("../fonts/HelveticaNeueLTPro-LtCn.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-LtCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-LtCn.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Roman.eot");
  src: url("../fonts/HelveticaNeueLTPro-Roman.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Roman.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Th.eot");
  src: url("../fonts/HelveticaNeueLTPro-Th.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Th.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Th.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-LtExO.eot");
  src: url("../fonts/HelveticaNeueLTPro-LtExO.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-LtExO.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-LtExO.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-MdExO.eot");
  src: url("../fonts/HelveticaNeueLTPro-MdExO.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-MdExO.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-MdExO.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Ex.eot");
  src: url("../fonts/HelveticaNeueLTPro-Ex.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Ex.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Ex.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-MdCn.eot");
  src: url("../fonts/HelveticaNeueLTPro-MdCn.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-MdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-MdCn.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Md.eot");
  src: url("../fonts/HelveticaNeueLTPro-Md.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Md.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Md.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

header,
main,
footer {
  float: left;
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

*:after, *:before {
  box-sizing: border-box;
}

.headerWrap,
.mainWrap,
.footerWrap {
  position: relative;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
div,
ul,
input,
blockquote,
span,
button,
i,
b,
em,
strong {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

body {
  overflow-x: hidden;
  position: relative;
}

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/*=====  End of MEDIA QUERIES  ======*/
/*==============================
=            MIXINS            =
==============================*/
/*=====  End of MIXINS  ======*/
/*========================================
=            EXTEND SELECTORS            =
========================================*/
.container,
.row {
  *zoom: 1;
}

.container:before,
.row:before, .container:after,
.row:after {
  content: " ";
  display: table;
}

.container:after,
.row:after {
  clear: both;
}

.services ul li .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headerWrap,
.mainWrap,
.footerWrap, .bottomSlidersWrap, .newsSlider .center_dsc, .prodhuesit_wrap, .prodhuesit_wrap .prodhuesit_header, .prodhuesit_wrap ul.accordion, .prodhuesit_wrap ul.accordion li, .inner_box, .inner_box .box_thumb, .inner_box .box_content {
  float: left;
  width: 100%;
  clear: both;
}

.mainBannerSlider .owl-stage-outer .item img, .newsSlider .item img, .prodhuesit_wrap ul.accordion .inner li .img_area img, .services ul li .itemBox img, .mainThumb img, .inner_box .box_thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*=====  End of EXTEND SELECTORS  ======*/
.column-1 {
  width: 8.33333%;
}

.column-2 {
  width: 16.66667%;
}

.column-3 {
  width: 25%;
}

.column-4 {
  width: 33.33333%;
}

.column-5 {
  width: 41.66667%;
}

.column-6 {
  width: 50%;
}

.column-7 {
  width: 58.33333%;
}

.column-8 {
  width: 66.66667%;
}

.column-9 {
  width: 75%;
}

.column-10 {
  width: 83.33333%;
}

.column-11 {
  width: 91.66667%;
}

.column-12 {
  width: 100%;
}

.wow {
  visibility: hidden;
}

[class*='column-'] {
  float: left;
  min-height: 1px;
}

.has_gutter [class*='column-'] {
  padding: 0 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .container {
    max-width: 732px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .container {
    max-width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1276px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .show-mob {
    display: none !important;
  }
  .show-tab {
    display: none !important;
  }
  .show-tab-big {
    display: none !important;
  }
}

.hidden-mob {
  display: block;
}

.hidden {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .column-mob-1 {
    width: 8.33333%;
  }
  .column-mob-2 {
    width: 16.66667%;
  }
  .column-mob-3 {
    width: 25%;
  }
  .column-mob-4 {
    width: 33.33333%;
  }
  .column-mob-5 {
    width: 41.66667%;
  }
  .column-mob-6 {
    width: 50%;
  }
  .column-mob-7 {
    width: 58.33333%;
  }
  .column-mob-8 {
    width: 66.66667%;
  }
  .column-mob-9 {
    width: 75%;
  }
  .column-mob-10 {
    width: 83.33333%;
  }
  .column-mob-11 {
    width: 91.66667%;
  }
  .column-mob-12 {
    width: 100%;
  }
  .show-mob {
    display: block !important;
  }
  .hidden-mob {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .column-tab-1 {
    width: 8.33333%;
  }
  .column-tab-2 {
    width: 16.66667%;
  }
  .column-tab-3 {
    width: 25%;
  }
  .column-tab-4 {
    width: 33.33333%;
  }
  .column-tab-5 {
    width: 41.66667%;
  }
  .column-tab-6 {
    width: 50%;
  }
  .column-tab-7 {
    width: 58.33333%;
  }
  .column-tab-8 {
    width: 66.66667%;
  }
  .column-tab-9 {
    width: 75%;
  }
  .column-tab-10 {
    width: 83.33333%;
  }
  .column-tab-11 {
    width: 91.66667%;
  }
  .column-tab-12 {
    width: 100%;
  }
  .show-tab {
    display: block !important;
  }
  .hidden-tab {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .column-tab-big-1 {
    width: 8.33333%;
  }
  .column-tab-big-2 {
    width: 16.66667%;
  }
  .column-tab-big-3 {
    width: 25%;
  }
  .column-tab-big-4 {
    width: 33.33333%;
  }
  .column-tab-big-5 {
    width: 41.66667%;
  }
  .column-tab-big-6 {
    width: 50%;
  }
  .column-tab-big-7 {
    width: 58.33333%;
  }
  .column-tab-big-8 {
    width: 66.66667%;
  }
  .column-tab-big-9 {
    width: 75%;
  }
  .column-tab-big-10 {
    width: 83.33333%;
  }
  .column-tab-big-11 {
    width: 91.66667%;
  }
  .column-tab-big-12 {
    width: 100%;
  }
  .show-tablet-big {
    display: block !important;
  }
  .hidden-tablet-big {
    display: none !important;
  }
}

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none;
}

.no_padding {
  padding: 0 !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

.no-flexboxlegacy body {
  display: block !important;
}

body {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  font-size: 14px;
  color: black;
  position: relative;
  overflow-x: hidden;
}

input,
textarea,
select,
option {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
option::-webkit-input-placeholder {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder,
option:-moz-placeholder {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder,
option::-moz-placeholder {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
option:-ms-input-placeholder {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

.iphone input,
.iphone textarea,
.iphone select,
.iphone option {
  appearance: none;
  border-radius: 0;
}

select::-ms-expand {
  display: none;
}

/*=================================
=            Normalize            =
=================================*/
mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/* Do not show the outline on the skip link target. */
#main[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*=====  End of Normalize  ======*/
.header_top_wrap {
  float: right;
}

.header_top_wrap .language_nav {
  float: left;
  margin-right: 7px;
  border-right: 1px solid #918f90;
  padding-right: 7px;
}

.header_top_wrap .language_nav li {
  float: left;
}

.header_top_wrap .language_nav li:last-child a:before {
  display: none;
}

.header_top_wrap .language_nav a {
  font-size: 14px;
  position: relative;
  text-decoration: none;
  display: block;
  color: #000;
}

.header_top_wrap .language_nav a:before {
  content: "/";
  color: #000;
  float: right;
  margin-left: 4px;
  margin-right: 4px;
}

.header_top_wrap .login_area {
  float: right;
}

.header_top_wrap .login_area a {
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
  background: url("../images/login_icon.png") no-repeat right;
  padding-right: 28px;
  padding-top: 1px;
}

.header_inner {
  float: left;
  width: 100%;
  padding: 30px 0;
}

.not_home .main_nav {
  border-top: 2px solid #c9c8c8;
  width: 100%;
  padding: 20px 0;
  ext-align: center;
}

.not_home .main_nav ul {
  float: none;
  display: block;
  text-align: center;
}

.not_home .main_nav li {
  display: inline-block;
  float: none;
  text-align: center;
}

.logo {
  float: left;
  width: 338px;
  height: 96px;
}

.main_nav {
  float: right;
  padding: 40px 0px 20px;
  border-bottom: 2px solid #c9c8c8;
}

.main_nav ul {
  float: left;
  width: 100%;
}

.main_nav ul li {
  float: left;
  margin-right: 30px;
}

.main_nav ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #000;
}

.main_nav ul li:last-child {
  margin-right: 0;
}

.second_nav {
  float: left;
  margin-right: 80px;
}

.second_nav ul {
  float: left;
  width: 100%;
}

.second_nav ul li {
  float: left;
  margin-right: 30px;
}

.second_nav ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
}

.second_nav ul li:last-child {
  margin-right: 0;
}

body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'Helvetica Neue LT Pro';
  src: url("../fonts/HelveticaNeueLTPro-Cn.eot");
  src: url("../fonts/HelveticaNeueLTPro-Cn.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-Cn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-Cn.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.banner_slider {
  float: left;
  width: 100%;
}

.mainBannerSlider {
  float: left;
  width: 100%;
  height: 495px;
}

.mainBannerSlider .owl-stage-outer {
  height: 100%;
}

.mainBannerSlider .owl-stage-outer .owl-stage {
  height: 100%;
}

.mainBannerSlider .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.mainBannerSlider .owl-stage-outer .item {
  display: block;
  height: 100%;
}

.mainBannerSlider .item {
  display: none;
  position: relative;
}

.mainBannerSlider .item a {
  color: #fff;
  text-decoration: none;
}

.mainBannerSlider .item:first-child {
  display: block;
}

.mainBannerSlider .item .bannerDsc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mainBannerSlider .item .bannerDsc h1 {
  font-size: 72px;
  font-weight: bold;
  text-transform: uppercase;
}

.mainBannerSlider .item .bannerDsc p {
  font-size: 16px;
  line-height: 1.3em;
  margin-top: 10px;
}

.mainBannerSlider .item .bannerDsc .bannerDscInner {
  float: left;
  width: 50%;
}

.mainBannerSlider .item .bannerDsc .banner-buttons {
  float: left;
  margin-top: 42px;
}

.mainBannerSlider .item .bannerDsc .banner-buttons a {
  float: left;
  margin-right: 58px;
  width: 194px;
  color: #000;
  background: #fff;
  background: #fff;
  border: 2px solid #fff;
  padding: 20px 0;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.mainBannerSlider .item .bannerDsc .banner-buttons a:hover {
  background: transparent;
  color: #fff;
}

.mainBannerSlider .productSliderHolder {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  padding: 38px;
}

.mainBannerSlider .priceTitle h1 {
  text-align: center;
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
}

.mainBannerSlider .sliderLocations {
  text-align: center;
  margin: 0 auto;
  margin-top: 55px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 40px;
  max-width: 90%;
}

.mainBannerSlider .sliderLocations p {
  font-size: 23px;
  text-transform: uppercase;
}

.mainBannerSlider .sliderLocations .owl-nav {
  display: block !important;
  position: absolute;
  width: 100%;
  top: 0px;
  margin-top: 0;
}

.mainBannerSlider .sliderLocations .owl-nav .owl-prev {
  float: left;
  text-indent: -9999em;
  margin: 0;
  padding: 0;
  background: none !important;
  margin-left: 35px;
}

.mainBannerSlider .sliderLocations .owl-nav .owl-prev:after {
  text-indent: 0;
  content: "";
  float: left;
  width: 20px;
  height: 24px;
  background: url("../images/left-arrow.png") no-repeat 0 0;
}

.mainBannerSlider .sliderLocations .owl-nav .owl-next {
  float: right;
  text-indent: -9999em;
  margin: 0;
  padding: 0;
  background: none !important;
  margin-right: 35px;
}

.mainBannerSlider .sliderLocations .owl-nav .owl-next:after {
  text-indent: 0;
  content: "";
  float: right;
  width: 20px;
  height: 24px;
  background: url("../images/right-arrow.png") no-repeat 0 0;
}

.mainBannerSlider .productSlider {
  text-align: center;
  margin-top: 55px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 20px;
}

.mainBannerSlider .productSlider p {
  font-size: 23px;
  text-transform: uppercase;
}

.mainBannerSlider .productSlider .owl-nav {
  display: block !important;
  position: absolute;
  width: 100%;
  top: 0px;
  margin-top: 0;
}

.mainBannerSlider .productSlider .owl-nav .owl-prev {
  float: left;
  text-indent: -9999em;
  margin: 0;
  padding: 0;
  background: none !important;
  margin-left: 60px;
}

.mainBannerSlider .productSlider .owl-nav .owl-prev:after {
  text-indent: 0;
  content: "";
  float: left;
  width: 20px;
  height: 24px;
  background: url("../images/left-arrow.png") no-repeat 0 0;
}

.mainBannerSlider .productSlider .owl-nav .owl-next {
  float: right;
  text-indent: -9999em;
  margin: 0;
  padding: 0;
  background: none !important;
  margin-right: 60px;
}

.mainBannerSlider .productSlider .owl-nav .owl-next:after {
  text-indent: 0;
  content: "";
  float: right;
  width: 20px;
  height: 24px;
  background: url("../images/right-arrow.png") no-repeat 0 0;
}

.mainBannerSlider .productType {
  text-align: center;
  margin: 0 auto;
  margin-top: 55px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 40px;
  max-width: 90%;
}

.mainBannerSlider .productType p {
  font-size: 23px;
  text-transform: uppercase;
  color: #fff;
}

.mainBannerSlider .priceArea {
  width: 85%;
  margin: 0 auto;
  margin-top: 30px;
  display: block;
}

.mainBannerSlider .priceArea .leftPrice {
  float: left;
}

.mainBannerSlider .priceArea .leftPrice p {
  font-size: 23px;
  color: #fff;
}

.mainBannerSlider .priceArea .rightPrice {
  float: right;
}

.mainBannerSlider .priceArea .rightPrice p {
  font-size: 23px;
  color: #fff;
}

.mainBannerSlider .blurry {
  float: left;
  width: 100%;
  height: 100%;
}

.bottomSlidersWrap {
  margin-top: 55px;
}

.newsSlider {
  float: left;
  position: relative;
  height: 305px;
  padding-right: 23px;
  overflow: hidden;
}

.newsSlider .owl-item,
.newsSlider .owl-stage,
.newsSlider .owl-stage-outer {
  height: 100%;
}

.newsSlider a {
  text-decoration: none;
  color: #fff;
}

.newsSlider .item {
  position: relative;
  height: 100%;
}

.newsSlider .item img {
  position: relative;
}

.newsSlider .content-wrap-holder {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  padding-bottom: 50px;
  padding-top: 50px;
  background: -webkit-linear-gradient(transparent, #000);
  background: -o-linear-gradient(transparent, #000);
  background: linear-gradient(transparent, #000);
}

.newsSlider .center_dsc h1 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 7px;
  text-transform: uppercase;
  line-height: 1.2em;
}

.newsSlider .center_dsc p {
  font-weight: normal;
  font-size: 16px;
}

.newsSlider .owl-dots {
  display: block;
  position: absolute;
  bottom: 18px;
  text-align: center;
  width: 100%;
}

.newsSlider .owl-dots .owl-dot.active span {
  background: #fff !important;
}

.newsSlider .owl-dots .owl-dot:hover span {
  background: #fff !important;
}

.newsSlider .owl-dots .owl-dot span {
  margin: 0 !important;
  margin-right: 5px !important;
  border: 1px solid #fff !important;
  background: none !important;
}

.listedItem {
  float: left;
  width: 100%;
  padding-left: 23px;
}

.listedItem ul {
  float: left;
  width: 100%;
}

.listedItem ul li {
  float: left;
  width: 100%;
  margin-bottom: 34px;
  border: 2px solid #adacac;
}

.listedItem ul li a {
  float: left;
  width: 100%;
  padding: 29px 0;
  text-align: center;
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
  font-size: 17px;
}

.listedItem ul .reports_icon {
  float: left;
  width: 20px;
  height: 20px;
  background: url("../images/reports_icon.svg") no-repeat 0 0;
  background-size: 20px;
  margin-left: 20px;
}

.listedItem ul .archive_icon {
  float: left;
  width: 20px;
  height: 20px;
  background: url("../images/archive_icon.svg") no-repeat 0 0;
  background-size: 20px;
  margin-left: 20px;
}

.listedItem ul .price_icon {
  float: left;
  width: 20px;
  height: 20px;
  background: url("../images/price_icon.svg") no-repeat 0 0;
  background-size: 20px;
  margin-left: 20px;
}

.prodhuesit_wrap .prodhuesit_header {
  border: 1px solid #95989A;
}

.prodhuesit_wrap .prodhuesit_header .leftSideZone {
  float: left;
  width: 50%;
  background: #FFDE17;
  padding: 27px 21px;
}

.prodhuesit_wrap .prodhuesit_header .leftSideZone .dropDownArea {
  float: left;
  max-width: 180px;
  position: relative;
}

.prodhuesit_wrap .prodhuesit_header .leftSideZone .open_dropdown {
  cursor: pointer;
  color: #000;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 180px;
  background: url(../images/down_arrow.svg) no-repeat right center;
  background-size: 20px;
  padding-right: 32px;
}

.prodhuesit_wrap .prodhuesit_header .leftSideZone .select-dropDownList {
  position: absolute;
  top: 100%;
  left: 0;
  float: left;
  margin-top: 10px;
  background: #fff;
  display: none;
}

.prodhuesit_wrap .prodhuesit_header .leftSideZone .select-dropDownList li {
  float: left;
  width: 100%;
}

.prodhuesit_wrap .prodhuesit_header .leftSideZone .select-dropDownList li a {
  color: #000;
  font-size: 14px;
  text-decoration: none;
  padding: 10px;
  border: 1px solid #ddd;
  float: left;
  width: 100%;
}

.prodhuesit_wrap .prodhuesit_header .leftSideZone .select-dropDownList li a:hover {
  background: #ddd;
  color: #fff;
}

.prodhuesit_wrap .prodhuesit_header .rightSideZone {
  float: left;
  width: 26%;
  background: #FFDE17;
  padding: 27px 21px;
}

.prodhuesit_wrap .prodhuesit_header .rightSideZone .dropDownRight {
  float: left;
  max-width: 180px;
  position: relative;
}

.prodhuesit_wrap .prodhuesit_header .rightSideZone span.open_dropdownList {
  cursor: pointer;
  color: #000;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 180px;
  background: url(../images/down_arrow.svg) no-repeat right center;
  background-size: 20px;
  padding-right: 32px;
}

.prodhuesit_wrap .prodhuesit_header .rightSideZone .right-dropDownList {
  position: absolute;
  top: 100%;
  left: 0;
  float: left;
  display: none;
  margin-top: 10px;
  background: #fff;
}

.prodhuesit_wrap .prodhuesit_header .rightSideZone .right-dropDownList li {
  float: left;
  width: 100%;
}

.prodhuesit_wrap .prodhuesit_header .rightSideZone .right-dropDownList li a {
  color: #000;
  font-size: 14px;
  text-decoration: none;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  float: left;
  width: 100%;
}

.prodhuesit_wrap .prodhuesit_header .rightSideZone .right-dropDownList li a:hover {
  background: #ddd;
  color: #fff;
}

.prodhuesit_wrap .prodhuesit_header .myCart {
  float: left;
  width: 24%;
  padding: 52px 20px;
}

.prodhuesit_wrap .prodhuesit_header .myCart h1 {
  max-width: 100%;
  font-size: 18px;
  text-align: center;
  margin-bottom: 0;
}

.prodhuesit_wrap .prodhuesit_header h1 {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 12px;
  text-transform: uppercase;
  max-width: 180px;
}

.prodhuesit_wrap ul.accordion {
  list-style: none;
  padding: 0;
}

.prodhuesit_wrap ul.accordion .inner {
  overflow: hidden;
  display: none;
}

.prodhuesit_wrap ul.accordion .inner.show {
  /*display: block;*/
}

.prodhuesit_wrap ul.accordion .inner li {
  border: 0;
}

.prodhuesit_wrap ul.accordion .inner li .img_area {
  float: left;
  width: 140px;
  height: 105px;
}

.prodhuesit_wrap ul.accordion .inner li .product_info {
  float: left;
  padding: 20px;
  background: #95989A;
  text-align: center;
}

.prodhuesit_wrap ul.accordion .inner li .product_info h1 {
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.prodhuesit_wrap ul.accordion li {
  border: 1px solid #95989A;
  margin-bottom: 1px;
}

.prodhuesit_wrap ul.accordion li a.toggle {
  width: 100%;
  padding: 21px;
  display: block;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 28px;
  transition: background .3s ease;
  background: url(../images/down_arrow.svg) no-repeat 98% center;
  background-size: 20px;
}

.select-dropDownList {
  display: none;
}

.default_wrap {
  float: left;
  width: 100%;
}

.services {
  float: left;
  width: 100%;
  margin-top: 22px;
}

.services ul {
  float: left;
  width: 100%;
}

.services ul li {
  float: left;
  width: 33.333%;
  padding: 0 45px;
  margin-top: 64px;
}

.services ul li .itemBox {
  width: 100%;
  height: 260px;
  position: relative;
  overflow: hidden;
}

.services ul li .itemBox img {
  float: left;
  position: relative;
}

.services ul li .overlay {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 38px;
  background: -webkit-linear-gradient(transparent, #000);
  background: -o-linear-gradient(transparent, #000);
  background: linear-gradient(transparent, #000);
  opacity: 0.80;
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
}

.services ul li .overlay:hover {
  opacity: 1;
}

.services ul li .overlay h2 {
  color: #fff;
  padding: 14px 15px;
  border: 2px solid #fff;
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
}

.rek_banner {
  float: left;
  width: 100%;
  margin: 60px 0 50px;
  text-align: center;
}

.mainTitle {
  float: left;
  width: 100%;
  border-bottom: 2px solid #686566;
  padding-bottom: 20px;
}

.mainTitle h1 {
  float: left;
  font-size: 24px;
  font-weight: 500;
}

.my_row {
  margin-left: -45px;
  margin-right: -45px;
}

.partners-value-wrap {
  float: left;
  width: 100%;
}

.partners-value-wrap .tweetsArea {
  width: 70%;
  margin-top: 80px;
}

.partners-value-wrap .tweetsArea iframe {
  font-size: 16px !important;
  transform: scale(0.85);
}

.partners-value-wrap .tweetsArea iframe .timeline-Tweet-text {
  font-size: 16px !important;
}

.partners-value-wrap .valuesArea {
  float: right;
  width: 456px;
  margin-top: 110px;
}

.partners-value-wrap .valuesArea ul {
  float: left;
  width: 100%;
}

.partners-value-wrap .valuesArea li {
  float: left;
  width: 100%;
  margin-bottom: 12px;
  border: 2px solid #adacac;
  color: #231f20;
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  display: block;
  text-align: right;
  padding: 20px;
  font-family: 'Helvetica Neue LT Pro';
  font-weight: bold;
  font-size: 22px;
}

.partners-value-wrap .valuesArea li a {
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  color: #231f20;
  width: 100%;
  display: block;
}

.partners-value-wrap .valuesArea li.usd {
  background: url(../images/us-flag-select.png) no-repeat 10px;
}

.partners-value-wrap .valuesArea li.eur {
  background: url(../images/eu-flag-select.png) no-repeat 10px;
}

.partners-value-wrap .valuesArea li.lek {
  background: url(../images/alb-flag-select.png) no-repeat 10px;
}

.box-divider {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.box-body {
  float: left;
  width: 100%;
  padding: 20px;
  background: #dddddd;
}

.input-group {
  padding: 0 15px;
  border-right: 2px solid #b9babb;
  float: left;
  width: 100%;
}

.input-group select {
  float: left;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: .25rem;
}

.input-group input[type="search"],
.input-group input[type="text"] {
  float: left;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: .25rem;
}

.input-group h1 {
  color: #95989a;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

html .average {
  margin-top: 13px;
  padding-top: 13px;
  border-top: 1px solid #b9babb !important;
}

.top-wrap-filters .input-group {
  min-height: 220px;
}

.top-wrap-filters .average {
  min-height: 1px;
}

.table_filters_wrap input[type="checkbox"] {
  display: none;
}

.table_filters_wrap input[type="checkbox"] + label {
  color: #95989a;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  cursor: pointer;
}

.table_filters_wrap input[type="checkbox"] + label span {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: -2px 10px 0 0;
  vertical-align: middle;
  background: url(../images/input-default.png) 0 0 no-repeat;
  cursor: pointer;
  margin-right: 7px;
  float: left;
}

.table_filters_wrap input[type="checkbox"]:checked + label span {
  background: url(../images/icon.svg) center no-repeat #8dc63f;
  background-size: 12px;
}

.table_filters_wrap input[type="radio"] {
  display: none;
}

.table_filters_wrap input[type="radio"] + label {
  color: #95989a;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  cursor: pointer;
}

.table_filters_wrap input[type="radio"] + label:last-child {
  margin-bottom: 0;
}

.table_filters_wrap input[type="radio"] + label span {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background: url(../images/input-default.png) 0 0 no-repeat;
  cursor: pointer;
  margin-right: 7px;
  float: left;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
}

.table_filters_wrap input[type="radio"]:checked + label span {
  background: url(../images/radio-on-button.svg) center no-repeat #fff;
  background-size: 16px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
}

.table_filters_wrap .form-group {
  padding-right: 10px;
}

.table_filters_wrap .form-group label {
  color: #95989a;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  float: left;
}

.bottom-wrap-filters .input-group {
  min-height: 125px;
}

.bottom-wrap-filters .input-group .month-field {
  margin-bottom: 10px;
}

.bottom-wrap-filters .download-docs {
  border-right: 0;
}

.bottom-wrap-filters .download-docs a {
  float: left;
  color: #95989a;
  font-size: 14px;
  margin-right: 10px;
  text-decoration: none;
}

.tabs-menu {
  float: left;
  width: 100%;
}

.tabs-menu li {
  float: left;
  width: 248px;
}

.tabs-menu li a {
  float: left;
  width: 100%;
  text-decoration: none;
  color: #95989a;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  background: #efefef;
  padding: 0px 26px;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  line-height: 1.2em;
}

.tabs-menu li.current a {
  background: #dddddd;
}

#tab-2 {
  display: none;
}

.border-right-0 {
  border-right: 0 !important;
}

.no-border {
  border: 0 !important;
}

.table_details {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.table_details table {
  width: 100%;
}

.table_details table td,
.table_details table tr {
  border: 1px solid #95989a;
  padding: 4px 6px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  color: #95989a;
}

.table_details table .head {
  border: 0;
}

.table_details table .head th {
  background: #ffde17;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 3px 9px;
  border-right: 1px solid #fff;
}

.table_details table tbody tr td.grey-bg {
  background: #dfdfdf;
  color: #fff;
  padding: 0;
  border-bottom: 0;
  font-weight: 600;
  text-align: center;
}

.table_details table tbody tr td.grey-bg span.prod-img {
  float: left;
  width: 50%;
  display: flex;
  height: 92px;
  align-items: center;
}

.table_details table tbody tr td.grey-bg span.prod-img img {
  width: auto;
  height: auto;
  max-width: 80px;
  max-height: 80px;
}

.table_details table tbody tr td.grey-bg span.prod-name {
  float: right;
  width: 50%;
  height: 92px;
  padding: 0px 0;
  line-height: 1.3em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.table_details .box-header h3 {
  text-align: center;
  color: #fff;
  background: #ff7017;
  padding: 18px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.mainThumb {
  float: left;
  width: 100%;
  height: 460px;
  overflow: hidden;
}

.contentArea {
  float: left;
  width: 100%;
  clear: both;
  margin-top: 20px;
}

.contentArea h1 {
  font-size: 20px;
  display: block;
  margin-bottom: 20px;
  line-height: 1.3em;
  font-weight: 600;
}

.contentArea p {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.3em;
}

.contentArea .excerpt {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3em;
}

.contentArea .excerpt p {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3em;
}

.contentArea p {
  font-weight: 400;
  color: #000;
  font-size: 15px;
  margin-top: 26px;
  line-height: 1.3em;
}

.contentArea strong,
.contentArea b {
  color: #000;
  margin-top: 20px;
  font-weight: 600;
}

.contentArea figcaption {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 1.32em;
  margin-top: 20px;
}

.contentArea h1,
.contentArea h2,
.contentArea h3,
.contentArea h4,
.contentArea h5,
.contentArea h6 {
  margin-top: 20px;
  color: #000;
  line-height: 1.25em;
  font-weight: 600;
}

.contentArea h2 {
  font-size: 18px;
}

.contentArea h3 {
  font-size: 18px;
}

.contentArea h4 {
  font-size: 16px;
}

.contentArea h5 {
  font-size: 16px;
}

.contentArea h6 {
  font-size: 14px;
}

.contentArea img {
  width: auto;
  text-align: center;
  height: auto;
  margin-top: 20px;
}

.contentArea iframe {
  width: 100%;
  margin-top: 20px !important;
  text-align: center;
}

.contentArea .fb_iframe_widget {
  float: left;
  width: 100%;
  margin-bottom: 35px;
}

.contentArea ul {
  float: left;
  width: 100%;
  margin-top: 34px;
}

.contentArea ul li {
  list-style-type: disc;
  font-weight: 400;
  color: #000;
  font-size: 16px;
  margin-bottom: 17px;
  line-height: 1.3em;
  margin-left: 20px;
  padding-left: 12px;
}

.contentArea ol {
  float: left;
  width: 100%;
  margin-top: 34px;
}

.contentArea ol li {
  list-style-type: decimal;
  font-weight: 400;
  color: #000;
  font-size: 16px;
  margin-bottom: 17px;
  line-height: 1.3em;
  margin-left: 20px;
  padding-left: 12px;
}

.page_title {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-bottom: 40px;
}

.raports_wrap {
  float: left;
  width: 100%;
  margin-top: 40px;
}

.raports_wrap ul {
  float: left;
  width: 100%;
}

.raports_wrap li {
  float: left;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.raports_wrap li p {
  color: #000;
  font-size: 16px;
  float: left;
  line-height: 1.3em;
  background: url("../images/reports_icon_green.svg") no-repeat 0 4px;
  background-size: 25px;
  padding-left: 50px;
}

.raports_wrap li a {
  float: right;
  margin-top: 4px;
  text-decoration: none;
  color: #8dc63f;
  padding-left: 24px;
  font-size: 11px;
  text-transform: uppercase;
  background: url("../images/icon_download.svg") no-repeat left center;
  background-size: 14px;
  padding-top: 4px;
}

.news_wrap {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.inner_box {
  margin-bottom: 30px;
  padding: 0 15px;
}

.inner_box .box_thumb {
  height: 180px;
}

.inner_box .box_content {
  margin-top: 10px;
}

.inner_box .box_content h2 {
  font-size: 18px;
  color: #000;
  line-height: 1.2em;
}

.inner_box .box_content p {
  margin-top: 10px;
  color: #000;
  font-size: 14px;
  line-height: 1.3em;
}

.inner_box .box_content span {
  color: grey;
  font-size: 13px;
  margin-top: 10px;
  float: left;
  width: 100%;
}

.clearleft {
  clear: left !important;
}

.kontakt_wrap {
  float: left;
  width: 100%;
  margin-top: 40px;
}

.kontakt_wrap .input_wrap {
  float: left;
  width: 50%;
  margin-bottom: 20px;
  padding-right: 20px;
}

.kontakt_wrap .input_wrap input {
  float: left;
  width: 100%;
  outline: none;
  box-shadow: none;
  border: 0;
  color: #000;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}

.kontakt_wrap .input_wrap input:focus {
  border-bottom: 1px solid #8dc63f;
}

.kontakt_wrap .input_wrap input[type="submit"] {
  border-bottom: 0;
  width: auto;
  background: #8dc63f;
  border: 2px solid #8dc63f;
  cursor: pointer;
  color: #fff;
  padding: 10px 30px;
  float: right;
  text-transform: uppercase;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.kontakt_wrap .input_wrap input[type="submit"]:hover {
  background: #fff;
  color: #8dc63f;
}

.kontakt_wrap .input_wrap textarea {
  width: 100%;
  height: 100px;
  resize: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.kontakt_wrap .input_wrap textarea:focus {
  border-bottom: 1px solid #8dc63f;
}

.kontakt_wrap .submit-btn-wrap {
  padding-right: 0;
}

.kontakt_wrap .message_wrap {
  padding-left: 0 !important;
}

.my_row_grid {
  margin-left: -15px;
  margin-right: -15px;
}

.side_bar {
  float: left;
  width: 100%;
  padding-left: 40px;
}

.side_bar .inner_box .box_content h2 {
  font-size: 16px;
}

.side_bar .raports_wrap {
  border-top: 2px solid #ddd;
  margin-top: 0;
  padding-top: 20px;
}

.side_bar .raports_wrap li p {
  font-size: 14px;
  padding-left: 40px;
}

.side_bar .raports_wrap li a {
  margin-top: 10px;
}

.side_bar .page_title {
  margin-bottom: 15px;
}

.side_bar .box_thumb {
  width: 65px;
  height: 65px;
  float: left;
}

.side_bar .inner_box .box_content {
  width: calc(100% - 65px);
  clear: none;
  margin-top: 0;
  padding-left: 15px;
  position: relative;
  height: 65px;
}

.side_bar .inner_box .box_content h2 {
  font-size: 13px;
  height: 45px;
  overflow: hidden;
}

.side_bar .inner_box .box_content span {
  font-size: 12px;
  position: absolute;
  bottom: 0;
}

.side_bar .inner_box {
  padding: 0;
}

.pagination {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-top: 10px;
  float: left;
  width: 100%;
}

.pagination li {
  display: inline-block;
  text-align: center;
}

.pagination li:first-child a {
  border-radius: 50px 0 0 50px;
}

.pagination li:last-child a {
  border-radius: 0 50px 50px 0;
}

.pagination a {
  float: left;
  display: block;
  font-size: 14px;
  text-decoration: none;
  padding: 5px 12px;
  color: #fff;
  margin-left: -1px;
  border: 1px solid transparent;
  line-height: 1.5;
  border-color: #ddd;
  color: #999;
  background: #fff;
}

.pagination a.active {
  cursor: default;
}

.pagination a:active {
  outline: none;
}

.pagination a:hover {
  color: #8dc63f;
  background-color: #eee;
}

.pagination a.active, .pagination a:active {
  border-color: #8dc63f;
  background: #8dc63f;
  color: #fff;
}

.gcw_mainFrnSlidAW {
  height: 300px;
}

.gcw_tableFrnSlidAW {
  height: 240px;
}

.news_items:nth-child(3n+1) {
  clear: left;
}

.logo_area {
  float: left;
  width: 76px;
  margin-top: 20px;
  margin-right: 67px;
  margin-left: 70px;
}

.logo_content {
  margin-top: 21px;
  text-align: left;
  float: left;
  width: 290px;
}

.logo_content a {
  text-decoration: none;
}

.logo_content span.qeveria {
  font-size: 15px;
  color: #898989;
  float: left;
  width: 100%;
}

.logo_content span.border {
  float: left;
  width: 33px;
  border-bottom: 1px solid #898989;
  padding-top: 9px;
  margin-bottom: 10px;
}

.logo_content span.ministria {
  font-weight: bold;
  font-size: 18px;
  color: #434242;
  line-height: 19px;
  float: left;
  width: 100%;
}

.footerWrap {
  float: left;
  width: 100%;
  background: #8DC63F;
  padding: 36px 26px 50px;
  margin-top: 56px;
}

.footerWrap .footer_logo {
  float: left;
  width: 388px;
  height: 94px;
}

.footerWrap .leftFooterDsc {
  margin-top: 30px;
}

.footerWrap .leftFooterDsc p {
  color: #fff;
  font-size: 15px;
  line-height: 1.3em;
}

.footerWrap .leftfooterside {
  max-width: 410px;
}

.footerWrap .footerSocialMedia {
  display: block;
  margin: 0 auto;
  max-width: 248px;
}

.footerWrap .footerSocialMedia h1 {
  font-size: 23px;
  color: #fff;
}

.footerWrap .footerSocialMedia ul {
  float: left;
  width: 100%;
  border-top: 2px solid #fff;
  padding-top: 15px;
  margin-top: 15px;
}

.footerWrap .footerSocialMedia ul li {
  float: left;
  margin-right: 33px;
}

.footerWrap .footerSocialMedia ul li:last-child {
  margin-right: 0;
}

.footerWrap .footerSocialMedia ul li.intagram img {
  width: 45px;
}

.footerWrap .footerSocialMedia ul li.facebook img {
  width: 30px;
}

.footerWrap .footerSocialMedia ul li.youtube img {
  width: 42px;
}

.footerWrap .footerSocialMedia ul li.googleplus img {
  width: 47px;
}

.footerWrap .footerSocialMedia ul li img {
  height: 30px;
}

.footerWrap .newsletter {
  float: right;
  max-width: 270px;
}

.footerWrap .newsletter h1 {
  font-size: 25px;
  text-transform: uppercase;
  color: #fff;
}

.footerWrap .newsletter .newsletterHolder {
  float: left;
  width: 100%;
  border-top: 2px solid #fff;
  padding-top: 15px;
  margin-top: 15px;
}

.footerWrap .newsletter .newsletterHolder p {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}

.footerWrap .newsletter form {
  margin-top: 23px;
}

.footerWrap .newsletter form input {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  outline: none;
}

.footerWrap .newsletter form input[type="text"] {
  width: 210px;
  opacity: 0.6;
  padding: 8px;
  float: left;
}

.footerWrap .newsletter form input[type="submit"] {
  background: url("../images/checked1.svg") no-repeat center #fff;
  background-size: 20px;
  text-indent: -9999em;
  cursor: pointer;
  padding: 8px 17px;
}

@media only screen and (max-width: 767px) {
  .logo {
    width: 100%;
    max-width: 80%;
  }
  .mainBannerSlider {
    height: 500px;
  }
  .mainBannerSlider .item .bannerDsc .bannerDscInner {
    width: 100%;
  }
  .mainBannerSlider .item .bannerDsc .bannerDscInner h1 {
    font-size: 20px;
  }
  .mainBannerSlider .item .bannerDsc .bannerDscInner p {
    font-size: 14px;
  }
  .mainBannerSlider .item .bannerDsc .bannerDscInner .banner-buttons a {
    width: auto;
    padding: 10px;
    margin-right: 14px;
  }
  .bottomSlidersWrap {
    margin-top: 40px;
  }
  .newsSlider {
    width: 100%;
    padding-right: 0;
  }
  .newsSlider .center_dsc h1 {
    font-size: 20px;
  }
  .listedItem {
    padding-left: 0;
    margin-top: 20px;
  }
  .my_row {
    margin-left: 0;
    margin-right: 0;
  }
  .services ul li {
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
  .rek_banner {
    margin: 30px 0 30px;
  }
  .partners-value-wrap .tweetsArea {
    margin-top: 30px;
  }
  .partners-value-wrap .tweetsArea iframe {
    transform: scale(1);
  }
  .partners-value-wrap .valuesArea {
    width: 100%;
    margin-top: 0px;
  }
  .services ul li .overlay h2 {
    font-size: 18px;
  }
  .footerWrap {
    padding: 20px 0;
  }
  .footerWrap .leftfooterside {
    max-width: 100%;
  }
  .footerWrap .footer_logo {
    width: 100%;
  }
  .footerWrap .footerSocialMedia {
    max-width: 100%;
  }
  .footerWrap .newsletter {
    float: left;
    max-width: 100%;
    margin-top: 30px;
    width: 100%;
  }
  .footerWrap .footerSocialMedia {
    margin-top: 30px;
  }
  .side_bar {
    padding-left: 0;
    margin-top: 20px;
  }
  .mainThumb {
    height: 320px;
  }
  .raports_wrap li p {
    font-size: 14px;
  }
  .raports_wrap {
    margin-top: 0;
  }
  .kontakt_wrap {
    margin-top: 0;
  }
  .kontakt_wrap .input_wrap {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .kontakt_wrap .input_wrap:nth-child(2n) {
    padding-left: 0;
  }
  .input-group h1 {
    font-size: 16px;
  }
  .tabs-menu li {
    width: 50%;
  }
  .tabs-menu li a {
    font-size: 14px;
  }
  .top-wrap-filters .input-group {
    min-height: auto;
    border-right: 0;
    margin-bottom: 20px;
  }
  .bottom-wrap-filters .input-group {
    min-height: auto;
    border-right: 0;
    margin-bottom: 20px;
  }
  .table_details table tbody tr td.grey-bg span.prod-img {
    width: 100px;
  }
  .table_details table tbody tr td.grey-bg span.prod-name {
    width: 100px;
  }
  .table-responsive {
    width: 100%;
    overflow-y: scroll;
  }
  .main_nav {
    float: left;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    padding: 20px 0;
  }
  .main_nav ul {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .header_top_wrap .language_nav {
    border-right: 0;
  }
  .header_top_wrap {
    display: none;
  }
  .second_nav {
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  .second_nav ul li {
    width: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .mainBannerSlider .item .bannerDsc h1 {
    font-size: 40px;
  }
  .mainBannerSlider .item .bannerDsc .bannerDscInner {
    width: 100%;
  }
  .newsSlider .center_dsc h1 {
    font-size: 24px;
  }
  .newsSlider {
    height: 315px;
  }
  .header_top_wrap {
    display: none;
    margin-top: 20px;
  }
  .hamburger {
    margin-top: 35px;
  }
  .my_row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .services ul li {
    padding: 0 15px;
  }
  .services ul li .overlay h2 {
    line-height: 1.3em;
    font-size: 20px;
  }
  .partners-value-wrap .valuesArea {
    width: 100%;
  }
  .footerWrap .footer_logo {
    width: 100%;
    height: auto;
  }
  .footerWrap .footer_logo img {
    max-width: 85%;
  }
  .footerWrap .footerSocialMedia ul li {
    margin-right: 25px;
  }
  .footerWrap .footerSocialMedia {
    padding-right: 10px;
  }
  .footerWrap .newsletter form input[type=text] {
    width: 200px;
  }
  .table-responsive {
    width: 100%;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .partners-value-wrap .valuesArea {
    width: 100%;
  }
  .footerWrap .footer_logo {
    width: 100%;
    height: auto;
  }
  .footerWrap .footer_logo img {
    max-width: 85%;
  }
  .mainBannerSlider .item .bannerDsc .bannerDscInner {
    width: 60%;
  }
  .hamburger {
    display: none;
  }
  .second_nav {
    margin-right: 40px;
  }
  .logo {
    width: 270px;
  }
  .header_top_wrap {
    margin-top: 20px;
  }
}

.hamburger {
  width: 30px;
  float: right;
  height: 14px;
  margin-top: 25px;
}

.hamburger span {
  width: 100%;
  float: left;
  height: 2px;
  background: #8dc63f;
  margin-bottom: 4px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.hamburger span:last-child {
  margin-bottom: 0;
}

.hamburger.toggled span:first-child {
  margin-top: 7px;
  margin-bottom: 2px;
  transform: rotate(45deg);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.hamburger.toggled span:nth-child(2) {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.hamburger.toggled span:nth-child(3) {
  margin-top: -10px;
  transform: rotate(-45deg);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}
