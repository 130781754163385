body {
  @include flexCenter(true, false);
  font-family: 'Helvetica Neue LT Pro';
  src: url('../fonts/HelveticaNeueLTPro-Cn.eot');
  src: url('../fonts/HelveticaNeueLTPro-Cn.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeueLTPro-Cn.woff') format('woff'), url('../fonts/HelveticaNeueLTPro-Cn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.banner_slider {
  float: left;
  width: 100%;
}

.mainBannerSlider {
  float: left;
  width: 100%;
  height: 495px;
  .owl-stage-outer {
    height: 100%;
    .owl-stage {
      height: 100%;
      .owl-item {
        height: 100%;
      }
    }
    .item {
      display: block;
      height: 100%;
      img {
        @extend %imgCropped;
      }
    }
  }
  .item {
    display: none;
    position: relative;
    a {
      color: #fff;
      text-decoration: none;
    }
    &:first-child {
      display: block;
    }
    .bannerDsc {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: left;
      -ms-flex-align: left;
      align-items: left;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      h1 {
        font-size: 72px;
        font-weight: bold;
        text-transform: uppercase;
      }
      p {
        font-size: 16px;
        line-height: 1.3em;
        margin-top: 10px;
      }
      .bannerDscInner {
        float: left;
        width: 50%;
      }
      .banner-buttons {
        float: left;
        margin-top: 42px;
        a {
          float: left;
          margin-right: 58px;
          width: 194px;
          color: #000;
          background: #fff;
          background: #fff;
          border: 2px solid #fff;
          padding: 20px 0;
          text-transform: uppercase;
          text-align: center;
          transition: $transition1;
          &:hover {
            background: transparent;
            color: #fff;
          }
        }
      }
    }
  }
  .productSliderHolder {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    padding: 38px;
  }
  .priceTitle {
    h1 {
      text-align: center;
      font-size: 25px;
      color: #fff;
      text-transform: uppercase;
    }
  }
  .sliderLocations {
    text-align: center;
    margin: 0 auto;
    margin-top: 55px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 40px;
    max-width: 90%;
    p {
      font-size: 23px;
      text-transform: uppercase;
    }
    .owl-nav {
      display: block !important;
      position: absolute;
      width: 100%;
      top: 0px;
      margin-top: 0;
      .owl-prev {
        float: left;
        text-indent: -9999em;
        margin: 0;
        padding: 0;
        background: none !important;
        margin-left: 35px;
        &:after {
          text-indent: 0;
          content: "";
          float: left;
          width: 20px;
          height: 24px;
          background: url('../images/left-arrow.png') no-repeat 0 0;
        }
      }
      .owl-next {
        float: right;
        text-indent: -9999em;
        margin: 0;
        padding: 0;
        background: none !important;
        margin-right: 35px;
        &:after {
          text-indent: 0;
          content: "";
          float: right;
          width: 20px;
          height: 24px;
          background: url('../images/right-arrow.png') no-repeat 0 0;
        }
      }
    }
  }
  .productSlider {
    text-align: center;
    margin-top: 55px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;
    p {
      font-size: 23px;
      text-transform: uppercase;
    }
    .owl-nav {
      display: block !important;
      position: absolute;
      width: 100%;
      top: 0px;
      margin-top: 0;
      .owl-prev {
        float: left;
        text-indent: -9999em;
        margin: 0;
        padding: 0;
        background: none !important;
        margin-left: 60px;
        &:after {
          text-indent: 0;
          content: "";
          float: left;
          width: 20px;
          height: 24px;
          background: url('../images/left-arrow.png') no-repeat 0 0;
        }
      }
      .owl-next {
        float: right;
        text-indent: -9999em;
        margin: 0;
        padding: 0;
        background: none !important;
        margin-right: 60px;
        &:after {
          text-indent: 0;
          content: "";
          float: right;
          width: 20px;
          height: 24px;
          background: url('../images/right-arrow.png') no-repeat 0 0;
        }
      }
    }
  }
  .productType {
    text-align: center;
    margin: 0 auto;
    margin-top: 55px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 40px;
    max-width: 90%;
    p {
      font-size: 23px;
      text-transform: uppercase;
      color: #fff;
    }
  }
  .priceArea {
    width: 85%;
    margin: 0 auto;
    margin-top: 30px;
    display: block;
    .leftPrice {
      float: left;
      p {
        font-size: 23px;
        color: #fff;
      }
    }
    .rightPrice {
      float: right;
      p {
        font-size: 23px;
        color: #fff;
      }
    }
  }
  .blurry {
    float: left;
    width: 100%;
    height: 100%;
  }
}

.bottomSlidersWrap {
  @extend %fullBlock;
  margin-top: 55px;
}

.newsSlider {
  float: left;
  position: relative;
  height: 305px;
  padding-right: 23px;
  overflow: hidden;
  .owl-item,
  .owl-stage,
  .owl-stage-outer {
    height: 100%;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  .item {
    position: relative;
    height: 100%;
    img {
      position: relative;
      @extend %imgCropped;
    }
  }
  .content-wrap-holder {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    background: -webkit-linear-gradient(transparent, #000);
    background: -o-linear-gradient(transparent, #000);
    background: linear-gradient(transparent, #000);
  }
  .owl-stage-outer {}
  .center_dsc {
    @extend %fullBlock;
    h1 {
      font-size: 30px;
      color: #fff;
      margin-bottom: 7px;
      text-transform: uppercase;
      line-height: 1.2em;
    }
    p {
      font-weight: normal;
      font-size: 16px;
    }
  }
  .owl-dots {
    display: block;
    position: absolute;
    bottom: 18px;
    text-align: center;
    width: 100%;
    .owl-dot {
      &.active {
        span {
          background: #fff !important;
        }
      }
      &:hover {
        span {
          background: #fff !important;
        }
      }
      span {
        margin: 0 !important;
        margin-right: 5px !important;
        border: 1px solid #fff!important;
        background: none !important;
      }
    }
  }
}

.listedItem {
  float: left;
  width: 100%;
  padding-left: 23px;
  ul {
    float: left;
    width: 100%;
    li {
      float: left;
      width: 100%;
      margin-bottom: 34px;
      border: 2px solid #adacac;
      a {
        float: left;
        width: 100%;
        padding: 29px 0;
        text-align: center;
        text-decoration: none;
        color: #000;
        text-transform: uppercase;
        font-size: 17px;
      }
    }
    .reports_icon {
      float: left;
      width: 20px;
      height: 20px;
      background: url('../images/reports_icon.svg') no-repeat 0 0;
      background-size: 20px;
      margin-left: 20px;
    }
    .archive_icon {
      float: left;
      width: 20px;
      height: 20px;
      background: url('../images/archive_icon.svg') no-repeat 0 0;
      background-size: 20px;
      margin-left: 20px;
    }
    .price_icon {
      float: left;
      width: 20px;
      height: 20px;
      background: url('../images/price_icon.svg') no-repeat 0 0;
      background-size: 20px;
      margin-left: 20px;
    }
  }
}

//Accordion 
.prodhuesit_wrap {
  @extend %fullBlock;
  .prodhuesit_header {
    @extend %fullBlock;
    border: 1px solid #95989A;
    .leftSideZone {
      float: left;
      width: 50%;
      background: #FFDE17;
      padding: 27px 21px;
      .dropDownArea {
        float: left;
        max-width: 180px;
        position: relative;
      }
      .open_dropdown {
        cursor: pointer;
        color: #000;
        font-size: 28px;
        font-weight: 700;
        text-transform: uppercase;
        max-width: 180px;
        background: url(../images/down_arrow.svg) no-repeat right center;
        background-size: 20px;
        padding-right: 32px;
      }
      .select-dropDownList {
        position: absolute;
        top: 100%;
        left: 0;
        float: left;
        margin-top: 10px;
        background: #fff;
        display: none;
        li {
          float: left;
          width: 100%;
          a {
            color: #000;
            font-size: 14px;
            text-decoration: none;
            padding: 10px;
            border: 1px solid #ddd;
            float: left;
            width: 100%;
            &:hover {
              background: #ddd;
              color: #fff;
            }
          }
        }
      }
    }
    .rightSideZone {
      float: left;
      width: 26%;
      background: #FFDE17;
      padding: 27px 21px;
      .dropDownRight {
        float: left;
        max-width: 180px;
        position: relative;
      }
      span.open_dropdownList {
        cursor: pointer;
        color: #000;
        font-size: 28px;
        font-weight: 700;
        text-transform: uppercase;
        max-width: 180px;
        background: url(../images/down_arrow.svg) no-repeat right center;
        background-size: 20px;
        padding-right: 32px;
      }
      .right-dropDownList {
        position: absolute;
        top: 100%;
        left: 0;
        float: left;
        display: none;
        margin-top: 10px;
        background: #fff;
        li {
          float: left;
          width: 100%;
          a {
            color: #000;
            font-size: 14px;
            text-decoration: none;
            padding: 10px;
            border-bottom: 1px solid #ddd;
            float: left;
            width: 100%;
            &:hover {
              background: #ddd;
              color: #fff;
            }
          }
        }
      }
    }
    .myCart {
      float: left;
      width: 24%;
      padding: 52px 20px;
      h1 {
        max-width: 100%;
        font-size: 18px;
        text-align: center;
        margin-bottom: 0;
      }
    }
    h1 {
      color: #000;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 12px;
      text-transform: uppercase;
      max-width: 180px;
    }
  }
  ul.accordion {
    @extend %fullBlock;
    list-style: none;
    padding: 0;
    .inner {
      overflow: hidden;
      display: none;
      &.show {
        /*display: block;*/
      }
      li {
        border: 0;
        .img_area {
          float: left;
          width: 140px;
          height: 105px;
          img {
            @extend %imgCropped;
          }
        }
        .product_info {
          float: left;
          padding: 20px;
          background: #95989A;
          text-align: center;
          h1 {
            font-weight: bold;
            font-size: 20px;
            text-transform: uppercase;
          }
        }
      }
    }
    li {
      @extend %fullBlock;
      border: 1px solid #95989A;
      margin-bottom: 1px;
      a.toggle {
        width: 100%;
        padding: 21px;
        display: block;
        color: #000;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 28px;
        transition: background .3s ease;
        background: url(../images/down_arrow.svg) no-repeat 98% center;
        background-size: 20px;
      }
    }
  }
}

.select-dropDownList {
  display: none;
}

.default_wrap {
  float: left;
  width: 100%;
}

.services {
  float: left;
  width: 100%;
  margin-top: 22px;
  ul {
    float: left;
    width: 100%;
    li {
      float: left;
      width: 33.333%;
      padding: 0 45px;
      margin-top: 64px;
      .itemBox {
        width: 100%;
        height: 260px;
        position: relative;
        overflow: hidden;
        img {
          float: left;
          @extend %imgCropped;
          position: relative;
        }
      }
      .overlay {
        @include flexCenter(true);
        @extend %centered;
        width: 100%;
        height: 100%;
        padding: 0 38px;
        background: -webkit-linear-gradient(transparent, #000);
        background: -o-linear-gradient(transparent, #000);
        background: linear-gradient(transparent, #000);
        opacity: 0.80;
        transition: $transition2;
        &:hover {
          opacity: 1;
        }
        h2 {
          color: #fff;
          padding: 14px 15px;
          border: 2px solid #fff;
          font-size: 26px;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
}

.rek_banner {
  float: left;
  width: 100%;
  margin: 60px 0 50px;
  text-align: center;
}

.mainTitle {
  float: left;
  width: 100%;
  border-bottom: 2px solid #686566;
  padding-bottom: 20px;
  h1 {
    float: left;
    font-size: 24px;
    font-weight: 500;
  }
}

.my_row {
  margin-left: -45px;
  margin-right: -45px;
}

.partners-value-wrap {
  float: left;
  width: 100%;
  .tweetsArea {
    width: 70%;
    margin-top: 80px;
    iframe {
      font-size: 16px !important;
      transform: scale(0.85);
      .timeline-Tweet-text {
        font-size: 16px !important;
      }
    }
  }
  .valuesArea {
    float: right;
    width: 456px;
    margin-top: 110px;
    ul {
      float: left;
      width: 100%;
    }
    li {
      float: left;
      width: 100%;
      margin-bottom: 12px;
      border: 2px solid #adacac;
      color: #231f20;
      font-size: 24px;
      font-weight: 500;
      text-decoration: none;
      display: block;
      text-align: right;
      padding: 20px;
      font-family: 'Helvetica Neue LT Pro';
      font-weight: bold;
      font-size: 22px;
      a {
        font-weight: 500;
        text-decoration: none;
        text-align: center;
        color: #231f20;
        width: 100%;
        display: block;
      }
      &.usd {
        background: url(../images/us-flag-select.png) no-repeat 10px;
      }
      &.eur {
        background: url(../images/eu-flag-select.png) no-repeat 10px;
      }
      &.lek {
        background: url(../images/alb-flag-select.png) no-repeat 10px;
      }
    }
  }
}

.box-divider {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.box-body {
  float: left;
  width: 100%;
  padding: 20px;
  background: #dddddd;
}

.input-group {
  padding: 0 15px;
  border-right: 2px solid #b9babb;
  float: left;
  width: 100%;
  select {
    float: left;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #55595c;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: .25rem;
  }
  input[type="search"],
  input[type="text"] {
    float: left;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #55595c;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: .25rem;
  }
  h1 {
    color: #95989a;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}

html .average {
  margin-top: 13px;
  padding-top: 13px;
  border-top: 1px solid #b9babb!important;
}

.top-wrap-filters {
  .input-group {
    min-height: 220px;
  }
  .average {
    min-height: 1px;
  }
}

.table_filters_wrap {
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label {
    color: #95989a;
    margin-bottom: 20px;
    float: left;
    width: 100%;
    cursor: pointer;
  }
  input[type="checkbox"] + label span {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: -2px 10px 0 0;
    vertical-align: middle;
    background: url(../images/input-default.png) 0 0 no-repeat;
    cursor: pointer;
    margin-right: 7px;
    float: left;
  }
  input[type="checkbox"]:checked + label span {
    background: url(../images/icon.svg) center no-repeat #8dc63f;
    background-size: 12px;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    color: #95989a;
    margin-bottom: 20px;
    float: left;
    width: 100%;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
  }
  input[type="radio"] + label span {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background: url(../images/input-default.png) 0 0 no-repeat;
    cursor: pointer;
    margin-right: 7px;
    float: left;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
  }
  input[type="radio"]:checked + label span {
    background: url(../images/radio-on-button.svg) center no-repeat #fff;
    background-size: 16px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
  }
  .form-group {
    padding-right: 10px;
    label {
      color: #95989a;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
      float: left;
    }
  }
}

.bottom-wrap-filters {
  .input-group {
    min-height: 125px;
  }
  .input-group .month-field {
    margin-bottom: 10px;
  }
  .download-docs {
    border-right: 0;
    a {
      float: left;
      color: #95989a;
      font-size: 14px;
      margin-right: 10px;
      text-decoration: none;
    }
  }
}

.tabs-menu {
  float: left;
  width: 100%;
  li {
    float: left;
    width: 248px;
    a {
      float: left;
      width: 100%;
      text-decoration: none;
      color: #95989a;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      background: #efefef;
      padding: 0px 26px;
      height: 60px;
      @include flexCenter(true);
      line-height: 1.2em;
    }
    &.current {
      a {
        background: #dddddd;
      }
    }
  }
}

#tab-2 {
  display: none;
}

.border-right-0 {
  border-right: 0 !important;
}

.no-border {
  border: 0 !important;
}

.table_details {
  float: left;
  width: 100%;
  margin-top: 20px;
  table {
    width: 100%;
    td,
    tr {
      border: 1px solid #95989a;
      padding: 4px 6px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      color: #95989a;
    }
    .head {
      border: 0;
      th {
        background: #ffde17;
        color: #fff;
        text-transform: uppercase;
        padding: 8px 3px 9px;
        border-right: 1px solid #fff;
      }
    }
    tbody {
      tr {
        td.grey-bg {
          background: #dfdfdf;
          color: #fff;
          padding: 0;
          border-bottom: 0;
          font-weight: 600;
          text-align: center;
          span.prod-img {
            float: left;
            width: 50%;
            display: flex;
            height: 92px;
            align-items: center;
            img {
              width: auto;
              height: auto;
              max-width: 80px;
              max-height: 80px;
            }
          }
          span.prod-name {
            float: right;
            width: 50%;
            height: 92px;
            padding: 0px 0;
            line-height: 1.3em;
            @include flexCenter(true);
          }
        }
      }
    }
  }
  .box-header {
    h3 {
      text-align: center;
      color: #fff;
      background: #ff7017;
      padding: 18px;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.mainThumb {
  float: left;
  width: 100%;
  height: 460px;
  overflow: hidden;
  img {
    @extend %imgCropped;
  }
}

.contentArea {
  @include fullBlock;
  margin-top: 20px;
  h1 {
    font-size: 20px;
    display: block;
    margin-bottom: 20px;
    line-height: 1.3em;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.3em;
  }
  .excerpt {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3em;
    p {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.3em;
    }
  }
  p {
    font-weight: 400;
    color: #000;
    font-size: 15px;
    margin-top: 26px;
    line-height: 1.3em;
  }
  strong,
  b {
    color: #000;
    margin-top: 20px;
    font-weight: 600;
  }
  figcaption {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 1.32em;
    margin-top: 20px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    color: #000;
    line-height: 1.25em;
    font-weight: 600;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
  img {
    width: auto;
    text-align: center;
    height: auto;
    margin-top: 20px;
  }
  iframe {
    width: 100%;
    margin-top: 20px!important;
    text-align: center;
  }
  .fb_iframe_widget {
    float: left;
    width: 100%;
    margin-bottom: 35px;
  }
  ul {
    float: left;
    width: 100%;
    margin-top: 34px;
    li {
      list-style-type: disc;
      font-weight: 400;
      color: #000;
      font-size: 16px;
      margin-bottom: 17px;
      line-height: 1.3em;
      margin-left: 20px;
      padding-left: 12px;
    }
  }
  ol {
    float: left;
    width: 100%;
    margin-top: 34px;
    li {
      list-style-type: decimal;
      font-weight: 400;
      color: #000;
      font-size: 16px;
      margin-bottom: 17px;
      line-height: 1.3em;
      margin-left: 20px;
      padding-left: 12px;
    }
  }
}

.page_title {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-bottom: 40px;
}

.raports_wrap {
  float: left;
  width: 100%;
  margin-top: 40px;
  ul {
    float: left;
    width: 100%;
  }
  li {
    float: left;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    p {
      color: #000;
      font-size: 16px;
      float: left;
      line-height: 1.3em;
      background: url('../images/reports_icon_green.svg') no-repeat 0 4px;
      background-size: 25px;
      padding-left: 50px;
    }
    a {
      float: right;
      margin-top: 4px;
      text-decoration: none;
      color: #8dc63f;
      padding-left: 24px;
      font-size: 11px;
      text-transform: uppercase;
      background: url('../images/icon_download.svg') no-repeat left center;
      background-size: 14px;
      padding-top: 4px;
    }
  }
}

.news_wrap {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.inner_box {
  @extend %fullBlock;
  margin-bottom: 30px;
  padding: 0 15px;
  .box_thumb {
    @extend %fullBlock;
    height: 180px;
    img {
      @extend %imgCropped;
    }
  }
  .box_content {
    @extend %fullBlock;
    margin-top: 10px;
    h2 {
      font-size: 18px;
      color: #000;
      line-height: 1.2em;
    }
    p {
      margin-top: 10px;
      color: #000;
      font-size: 14px;
      line-height: 1.3em;
    }
    span {
      color: grey;
      font-size: 13px;
      margin-top: 10px;
      float: left;
      width: 100%;
    }
  }
}

.clearleft {
  clear: left !important;
}

.kontakt_wrap {
  float: left;
  width: 100%;
  margin-top: 40px;
  .input_wrap {
    float: left;
    width: 50%;
    margin-bottom: 20px;
    padding-right: 20px;
    input {
      float: left;
      width: 100%;
      outline: none;
      box-shadow: none;
      border: 0;
      color: #000;
      font-size: 16px;
      border-bottom: 1px solid #ddd;
      &:focus {
        border-bottom: 1px solid #8dc63f;
      }
    }
    input[type="submit"] {
      border-bottom: 0;
      width: auto;
      background: #8dc63f;
      border: 2px solid #8dc63f;
      cursor: pointer;
      color: #fff;
      padding: 10px 30px;
      float: right;
      text-transform: uppercase;
      font-size: 14px;
      transition: $transition1;
      &:hover {
        background: #fff;
        color: #8dc63f;
      }
    }
    textarea {
      width: 100%;
      height: 100px;
      resize: none;
      border: none;
      outline: none;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      &:focus {
        border-bottom: 1px solid #8dc63f;
      }
    }
  }
  .submit-btn-wrap {
    padding-right: 0;
  }
  .message_wrap {
    padding-left: 0 !important;
  }
}

.my_row_grid {
  margin-left: -15px;
  margin-right: -15px;
}

.side_bar {
  float: left;
  width: 100%;
  padding-left: 40px;
  .inner_box .box_content {
    h2 {
      font-size: 16px;
    }
  }
  .raports_wrap {
    border-top: 2px solid #ddd;
    margin-top: 0;
    padding-top: 20px;
    li {
      p {
        font-size: 14px;
        padding-left: 40px;
      }
      a {
        margin-top: 10px;
      }
    }
  }
  .page_title {
    margin-bottom: 15px;
  }
  .box_thumb {
    width: 65px;
    height: 65px;
    float: left;
  }
  .inner_box .box_content {
    width: calc(100% - 65px);
    clear: none;
    margin-top: 0;
    padding-left: 15px;
    position: relative;
    height: 65px;
    h2 {
      font-size: 13px;
      height: 45px;
      overflow: hidden;
    }
    span {
      font-size: 12px;
      position: absolute;
      bottom: 0;
    }
  }
  .inner_box {
    padding: 0;
  }
}

.pagination {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-top: 10px;
  float: left;
  width: 100%;
  li {
    display: inline-block;
    text-align: center;
    &:first-child {
      a {
        border-radius: 50px 0 0 50px;
      }
    }
    &:last-child {
      a {
        border-radius: 0 50px 50px 0;
      }
    }
  }
  a {
    float: left;
    display: block;
    font-size: 14px;
    text-decoration: none;
    padding: 5px 12px;
    color: #fff;
    margin-left: -1px;
    border: 1px solid transparent;
    line-height: 1.5;
    &.active {
      cursor: default;
    }
    &:active {
      outline: none;
    }
    border-color:#ddd;
    color:#999;
    background:#fff;
    &:hover {
      color: #8dc63f;
      background-color: #eee;
    }
    &.active,
    &:active {
      border-color: #8dc63f;
      background: #8dc63f;
      color: #fff;
    }
  }
}

.gcw_mainFrnSlidAW {
  height: 300px;
}

.gcw_tableFrnSlidAW {
  height: 240px;
}

.news_items:nth-child(3n+1) {
  clear: left;
}

.logo_area {
  float: left;
  width: 76px;
  margin-top: 20px;
  margin-right: 67px;
  margin-left: 70px;
}

.logo_content {
  margin-top: 21px;
  text-align: left;
  float: left;
  width: 290px;
}

.logo_content a {
  text-decoration: none;
}

.logo_content span.qeveria {
  font-size: 15px;
  color: #898989;
  float: left;
  width: 100%;
}

.logo_content span.border {
  float: left;
  width: 33px;
  border-bottom: 1px solid #898989;
  padding-top: 9px;
  margin-bottom: 10px;
}

.logo_content span.ministria {
  font-weight: bold;
  font-size: 18px;
  color: #434242;
  line-height: 19px;
  float: left;
  width: 100%;
}
